import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoute from "./helpers/AppRoute";

import { DefaultLayout } from "./layouts";
//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Home = lazy(() => import("./pages/Home"));
const Checkout = lazy(() => import("./pages/Checkout"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Home}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              exact
              component={Checkout}
            />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
