import React, {Component} from "react";
import { connect } from "react-redux";
import { theme } from "../../components";

import {
  Anchor,
  Box,
  Button,
  DropButton,
  Grommet,
  Image,
  ResponsiveContext,
  Stack,
  Text,
  Tabs,
  Tab
} from 'grommet';

import { Menu, Cart, UserSettings, Logout } from 'grommet-icons';

class Header extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
       desktopNav: true,
       layerNav: false,
    }
  }

  toggleMenu() {
    this.setState({ desktopNav: this.state.desktopNav ? false : true });
  }

  scrollFeatures() {
    let element = document.getElementById('features');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollIntro() {
    let element = document.getElementById('intro');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollModules() {
    let element = document.getElementById('modules');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollPricing() {
    let element = document.getElementById('pricing');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  componentDidMount(){
    
  }

  
  render(){
    return (
      <Grommet theme={theme}>
        <ResponsiveContext.Consumer>
          {size => (
              <Box id="header" background="#fff" elevation="small">
                {( size !== 'small') ? (
                  <Box className="page-container" direction="row" align="center" justify="between">
                    <Box id="logo">
                      <Image src="logo.png" />
                    </Box>
                    <Box direction="row" align="center" justify="end" gap="40px">
                      <Box id="navigation" direction="row" align="end" gap="40px">
                        <Text className="clickable" size="16px" onClick={() => this.scrollIntro()}>Solusi</Text>
                        <Text className="clickable" size="16px" onClick={() => this.scrollFeatures()}>Fitur</Text>
                        <Text className="clickable" size="16px" onClick={() => this.scrollModules()}>Modul</Text>
                        <Text className="clickable" size="16px" onClick={() => this.scrollPricing()}>Harga</Text>
                      </Box>
                      <Box className="panel-action" justify="end">
                        <Box direction="row" align="center" gap="20px">
                          <Button primary size="small" color="accent-1" label="Daftar Gratis" onClick={() => this.scrollPricing()} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ): (
                  <Box className="page-container" direction="row" align="center" justify="center">
                    <Box id="logo">
                      <Image src="logo.png" />
                    </Box>
                  </Box>
                )}
              </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
